"use client";

import { useTranslate } from "@/client/hooks";

// Make CourseTreeBuilder abstract with an abstract translate method
abstract class AbstractCourseTreeBuilder {
  course: any;
  tree: any;

  constructor(course: any) {
    this.tree = null;
    this.course = course;
  }

  // Abstract method for translation
  abstract translate(text: string): string;

  buildTreeNode({
    branch,
    text = "",
    translateText = true,
    textReference = null,
    children = [],
    newChild = null,
    deleteReference = null,
    deleteActive = false,
  }: {
    branch: string;
    text?: string;
    translateText?: boolean;
    textReference?: string | null;
    children?: any[];
    newChild?: any | null;
    deleteReference?: any | null;
    deleteActive?: boolean;
  }) {
    return {
      branch,
      text: translateText ? this.translate(text) : text,
      textReference,
      children,
      newChild,
      deleteReference,
      deleteActive,
    };
  }

  buildNewChild({
    buttonText = "",
    parentReference = "",
    bodyData = {},
  }: {
    buttonText?: string;
    parentReference: string;
    bodyData: any;
  }) {
    return {
      buttonText: buttonText ? this.translate(buttonText) : buttonText,
      parentReference,
      bodyData,
    };
  }

  buildNewRecord(schema: any) {
    const translateRecursively = (data: any) => {
      if (typeof data === "string") return this.translate(data);
      if (Array.isArray(data)) return data.map(translateRecursively);
      if (typeof data === "object" && data != null) {
        const translatedEntries = Object.entries(data).map(([key, value]) => [
          key,
          translateRecursively(value),
        ]);
        return Object.fromEntries(translatedEntries);
      }
      return data;
    };

    return translateRecursively(schema);
  }

  buildCourseTree() {
    const rootBranch = "root";
    const rootNode = this.buildTreeNode({
      branch: rootBranch,
      text: this.course.title,
      textReference: `title`,
      children: [
        this.buildTreeNode({
          branch: `${rootBranch}.1`,
          text: "Learning Outcomes",
          newChild: this.buildNewChild({
            buttonText: "Add New Learning Outcome",
            parentReference: `learningOutcomes`,
            bodyData: this.buildNewRecord({
              statement: "New Learning Outcome",
              performanceCriteria: [
                { description: "New Performance Criteria" },
              ],
              scope: "New Scope",
              domain: "New Domain",
            }),
          }),
          children: this.course.learningOutcomes.map((lo: { statement: any; domain: any; scope: any; performanceCriteria: any[]; modules: any[]; }, loIdx: any) => {
            const loBranch = `${rootBranch}.1.${loIdx}`;
            return this.buildTreeNode({
              branch: loBranch,
              text: lo.statement,
              translateText: false,
              textReference: `learningOutcomes.${loIdx}.statement`,
              deleteActive: this.course.learningOutcomes.length > 1,
              deleteReference: {
                parentReference: `learningOutcomes`,
                popIndex: loIdx,
              },
              children: [
                this.buildTreeNode({
                  branch: `${loBranch}.0`,
                  text: "Domain",
                  children: [
                    this.buildTreeNode({
                      branch: `${loBranch}.0.0`,
                      text: lo.domain,
                      translateText: false,
                      textReference: `learningOutcomes.${loIdx}.domain`,
                    }),
                  ],
                }),
                this.buildTreeNode({
                  branch: `${loBranch}.1`,
                  text: "Scope",
                  children: [
                    this.buildTreeNode({
                      branch: `${loBranch}.1.0`,
                      text: lo.scope,
                      translateText: false,
                      textReference: `learningOutcomes.${loIdx}.scope`,
                    }),
                  ],
                }),
                this.buildTreeNode({
                  branch: `${loBranch}.2`,
                  text: "Performance Criteria",
                  newChild: this.buildNewChild({
                    buttonText: "Add New Performance Criteria",
                    parentReference: `learningOutcomes.${loIdx}.performanceCriteria`,
                    bodyData: this.buildNewRecord({
                      description: "New Performance Criteria",
                    }),
                  }),
                  children: lo.performanceCriteria.map((pc: { description: any; }, pcIdx: any) => {
                    const pcBranch = `${loBranch}.2.${pcIdx}`;
                    return this.buildTreeNode({
                      branch: pcBranch,
                      textReference: `learningOutcomes.${loIdx}.performanceCriteria.${pcIdx}.description`,
                      text: pc.description,
                      deleteActive: lo.performanceCriteria.length > 1,
                      deleteReference: {
                        parentReference: `learningOutcomes.${loIdx}.performanceCriteria`,
                        popIndex: pcIdx,
                      },
                    });
                  }),
                }),
                this.buildTreeNode({
                  branch: `${loBranch}.3`,
                  text: "Modules",
                  newChild: this.buildNewChild({
                    buttonText: "Add New Module",
                    parentReference: `modules`,
                    bodyData: this.buildNewRecord({
                      name: "New Module",
                      lessons: [
                        {
                          name: "New Lesson",
                          learningOutcomes: ["New Learning Outcome"],
                          content: "New Lesson Content",
                          otherInfo: "New Lesson Info",
                          sampleQuestions: [
                            {
                              type: "New Questions Type",
                              questions: "New Questions List",
                            },
                          ],
                        },
                      ],
                    }),
                  }),
                  children: lo.modules.map((mod: { name: any; lessons: any[]; }, modIdx: string | number) => {
                    const moduleBranch = `${rootBranch}.0.${modIdx}`;
                    return this.buildTreeNode({
                      branch: moduleBranch,
                      text: mod.name,
                      translateText: false,
                      textReference: `modules.${modIdx}.name`,
                      deleteReference: { parentReference: "modules", popIndex: modIdx },
                      deleteActive: lo.modules.length > 1,
                      newChild: this.buildNewChild({
                        buttonText: "Add New Lesson",
                        parentReference: `modules.${modIdx}.lessons`,
                        bodyData: this.buildNewRecord({
                          name: "New Lesson",
                          learningOutcomes: ["New Learning Outcome"],
                          content: "New Lesson Content",
                          otherInfo: "New Lesson Info",
                          sampleQuestions: [
                            {
                              type: "New Questions Type",
                              questions: "New Questions List",
                            },
                          ],
                        }),
                      }),
                      children: mod.lessons.map((lesson: { name: any; content: any; sampleQuestions: { type: any; questions: any; }[]; learningOutcomes: any[]; otherInfo: any; }, lessonIdx: any) => {
                        const lessonBranch = `${moduleBranch}.${lessonIdx}`;
                        return this.buildTreeNode({
                          branch: lessonBranch,
                          text: lesson.name,
                          translateText: false,
                          textReference: `modules.${modIdx}.lessons.${lessonIdx}.name`,
                          deleteActive: mod.lessons.length > 1,
                          deleteReference: {
                            parentReference: `modules.${modIdx}.lessons`,
                            popIndex: lessonIdx,
                          },
                          children: [
                            this.buildTreeNode({
                              branch: `${lessonBranch}.0`,
                              text: "Content",
                              children: [
                                this.buildTreeNode({
                                  branch: `${lessonBranch}.0.0`,
                                  textReference: `modules.${modIdx}.lessons.${lessonIdx}.content`,
                                  text: lesson.content,
                                  translateText: false,
                                }),
                              ],
                            }),
                            this.buildTreeNode({
                              branch: `${lessonBranch}.1`,
                              text: "Sample Questions",
                              newChild: this.buildNewChild({
                                buttonText: "Add New Sample Questions",
                                parentReference: `modules.${modIdx}.lessons.${lessonIdx}.sampleQuestions`,
                                bodyData: this.buildNewRecord({
                                  type: "New Questions Type",
                                  questions: "New Questions List",
                                }),
                              }),
                              children: lesson.sampleQuestions.map(
                                ({ type, questions }: any, sqIdx: any) => {
                                  const sqsBranch = `${lessonBranch}.1.${sqIdx}`;
                                  return this.buildTreeNode({
                                    branch: sqsBranch,
                                    text: type,
                                    translateText: false,
                                    textReference: `modules.${modIdx}.lessons.${lessonIdx}.sampleQuestions.${sqIdx}.type`,
                                    deleteActive: lesson.sampleQuestions.length > 1,
                                    deleteReference: {
                                      parentReference: `modules.${modIdx}.lessons.${lessonIdx}.sampleQuestions`,
                                      popIndex: sqIdx,
                                    },
                                    children: [
                                      this.buildTreeNode({
                                        branch: `${sqsBranch}.0`,
                                        text: questions,
                                        translateText: false,
                                        textReference: `modules.${modIdx}.lessons.${lessonIdx}.sampleQuestions.${sqIdx}.questions`,
                                      }),
                                    ],
                                  });
                                }
                              ),
                            }),
                            this.buildTreeNode({
                              branch: `${lessonBranch}.2`,
                              text: "Learning Outcomes",
                              newChild: this.buildNewChild({
                                buttonText: "Add New Learning Outcome",
                                parentReference: `modules.${modIdx}.lessons.${lessonIdx}.learningOutcomes`,
                                bodyData: "New Learning Outcome",
                              }),
                              children: lesson.learningOutcomes.map((lo: any, loIdx: any) => {
                                const loBranch = `${lessonBranch}.2.${loIdx}`;
                                return this.buildTreeNode({
                                  branch: loBranch,
                                  text: lo,
                                  translateText: false,
                                  textReference: `modules.${modIdx}.lessons.${lessonIdx}.learningOutcomes.${loIdx}`,
                                  deleteReference: {
                                    parentReference: `modules.${modIdx}.lessons.${lessonIdx}.learningOutcomes`,
                                    popIndex: loIdx,
                                  },
                                  deleteActive: lesson.learningOutcomes.length > 1,
                                });
                              }),
                            }),
                            this.buildTreeNode({
                              branch: `${lessonBranch}.3`,
                              text: "Other Info",
                              children: [
                                this.buildTreeNode({
                                  branch: `${lessonBranch}.3.0`,
                                  text: lesson.otherInfo,
                                  translateText: false,
                                  textReference: `modules.${modIdx}.lessons.${lessonIdx}.otherInfo`,
                                }),
                              ],
                            }),
                          ],
                        });
                      }),
                    });
                  }),
                }),
              ],
            });
          }),
        }),
      ],
    });

    this.tree = rootNode;
    return rootNode;
  }

  rebuildCourseTree(course: any) {
    this.course = course;
    const rootNode = this.buildCourseTree();
    return rootNode;
  }

  getTreeNodeByBranch(branch: string) {
    const indexes = branch.split(".");
    const node = indexes.reduce((acc, currentIdx) => {
      if (currentIdx == "root") return acc;
      return acc.children[currentIdx];
    }, this.tree);

    return node;
  }
}

const useCourseTreeBuilder = () => {
  const translate = useTranslate();

  // Implement the translate method inside the hook
  class CourseTreeBuilder extends AbstractCourseTreeBuilder {
    translate(text: string) {
      return translate(text);
    }
  }

  return CourseTreeBuilder;
};

export default useCourseTreeBuilder;
