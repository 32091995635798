"use client"

import { SEO_API, SEO_API_KEY } from "@/constants"
import { getDict, updateDict, getPending, pushPending, popPending } from "@/client/store/reducers/dictReducer"
import { getLang } from "@/client/store/reducers/langReducer"
import { AxiosRequest, devConsoleLog } from "@/client/utils"
import { useDispatch, useSelector } from "react-redux"
import { useCallback } from "react"
import { cloneDeep } from "lodash"


const useTranslate = () => {
    const lang = useSelector(getLang);
    const dict = useSelector(getDict);
    const pending = useSelector(getPending);
    const dispatch = useDispatch();

    const translate = useCallback((keyword) => {
        if (!keyword) return "";

        // initialize translation with original keyword
        let translation = keyword;

        // get keyword prev translations
        const keywordTranslations = cloneDeep(dict[keyword]) || {}

        // check if translation already exists
        if (keywordTranslations[lang]) {
            translation = keywordTranslations[lang];
        } else if (!pending.includes(keyword)) {    // Avoid making a request if it's already pending
            // Mark as pending
            dispatch(pushPending({ keyword }));

            AxiosRequest.post(`${SEO_API}/translation/addOrUpdateTrans`,
                { keyword, lang, solution: "lwe" },
                { headers: { API_KEY: SEO_API_KEY } }
            ).then((res) => {
                // upate translation
                translation = res.data[lang]

                // upate dictionary
                keywordTranslations[lang] = res.data[lang]
                dispatch(updateDict({ keyword, keywordTranslations }));
            }).catch((err) => {
                devConsoleLog(`Failed to translate '${keyword}': ${err.message}`);
            }).finally(() => {
                // pop from pending
                dispatch(popPending({ keyword }));
            })
        }

        return translation;
    }, [lang, dict, pending, dispatch]);

    return translate;
};

export default useTranslate