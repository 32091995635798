"use client"

import { createSlice } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash";
import { AMAZON_ACCESS_KEY, AMAZON_SECRET_KEY, AMAZON_REGION, AMAZON_BUCKET_NAME } from "@/constants";
import { devConsoleLog } from "@/client/utils";


const dictSlice = createSlice({
    name: 'dict',
    initialState: { translations: null, pending: [] },
    reducers: {
        setDict: (state, action) => {
            state.translations = action.payload;
        },
        clearDict: (state) => {
            state.translations = null;
        },
        updateDict: (state, action) => {
            const { keyword, keywordTranslations } = action.payload
            
            let updated = cloneDeep(state.translations)
            updated[keyword] = keywordTranslations

            state.translations = updated
        },
        updateDictMany: (state, action) => {
            const newTranslations = action.payload
            
            let updated = cloneDeep(state.translations)
            newTranslations.forEach(newTrans => {
                const { keyword, keywordTranslations } = newTrans
                updated[keyword] = keywordTranslations
            })

            state.translations = updated
        },
        pushPending: (state, action) => {
            const { keyword } = action.payload
            state.pending.push(keyword)
        },
        popPending: (state, action) => {
            const { keyword } = action.payload
            const kwIdx = state.pending.findIndex(kw => kw == keyword)
            state.pending.splice(kwIdx, 1)

            if (state.pending.length == 0) {
                // AWS S3 Configuration
                const s3 = new AWS.S3({
                    accessKeyId: AMAZON_ACCESS_KEY,
                    secretAccessKey: AMAZON_SECRET_KEY,
                    region: AMAZON_REGION,
                });

                // write to s3
                s3.upload({
                    Bucket: AMAZON_BUCKET_NAME,
                    Key: 'dict.json',
                    Body: JSON.stringify(state.translations),
                    ContentType: "application/json",
                }).promise().then(result => {
                    devConsoleLog(`dict saved to s3 at: ${result.Location}`);
                })
            }
        },
    },
})

// Extract and export each action creator by name
export const { setDict, clearDict, updateDict, updateDictMany, pushPending, popPending } = dictSlice.actions

// selectors
export const getDict = (state) => state.dict.translations;
export const getPending = (state) => state.dict.pending;

// Export the reducer, either as a default or named export
export default dictSlice.reducer