"use client"

import { LWE_HMAC } from '@/constants';
import axios from 'axios';
import crypto from 'crypto';
import loadJsonCourse from "./loadJsonCourse"
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';


export {
    loadJsonCourse,
}


export const ReactSwal = withReactContent(Swal);


export function devConsoleLog(...args) {
    if ("development" === process.env.NODE_ENV) {
        console.log(...args);
    }
}


export const AxiosRequest = axios.create({
    withCredentials: true,
    validateStatus: status => (status >= 200 && status < 300) || [422, 409, 403].includes(status),
    timeout: 0,
})


export const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};


export const getTimeAgo = (dateString) => {
    const targetDate = new Date(dateString);
    const now = new Date();
    const diffInMs = now - targetDate;

    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30); // Approximate months
    const diffInYears = Math.floor(diffInDays / 365); // Approximate years

    let timeAgo;

    if (diffInYears > 0) {
        timeAgo = `${diffInYears} year(s) ago`;
    } else if (diffInMonths > 0) {
        timeAgo = `${diffInMonths} month(s) ago`;
    } else if (diffInWeeks > 0) {
        timeAgo = `${diffInWeeks} week(s) ago`;
    } else if (diffInDays > 0) {
        timeAgo = `${diffInDays} day(s) ago`;
    } else if (diffInHours > 0) {
        timeAgo = `${diffInHours} hour(s) ago`;
    } else if (diffInMinutes > 0) {
        timeAgo = `${diffInMinutes} minute(s) ago`;
    } else {
        timeAgo = `${diffInSeconds} second(s) ago`;
    }

    return timeAgo;
}


export const getHumanReadableDuration = (startDate, endDate) => {
    const msDiff = new Date(endDate) - new Date(startDate); // difference in milliseconds

    // Calculate units
    const seconds = Math.floor(msDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    // Get the remaining hours, minutes, and seconds
    const remainingHours = hours;
    const remainingMinutes = minutes % 60;
    const remainingSeconds = seconds % 60;

    return `${+remainingHours > 0 ? `${remainingHours} hours,` : ''} ${remainingMinutes} minutes, ${remainingSeconds} seconds`.trim();
}


export const cleanWord = (word) => word?.toLowerCase()?.trim(); // word.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").toLowerCase().trim();


// lwe decryption function used in fawaterak
export const decrypt = (encryptedText) => {
    const algorithm = 'aes-256-cbc';
    const textParts = encryptedText.split(':');
    const iv = Buffer.from(textParts.shift(), 'hex');
    const encryptedTextBuffer = Buffer.from(textParts.join(':'), 'hex');

    // Create decipher instance
    const decipher = crypto.createDecipheriv(algorithm, Buffer.from(LWE_HMAC), iv);

    // Decrypt the text
    let decrypted = decipher.update(encryptedTextBuffer, 'hex', 'utf8');
    decrypted += decipher.final('utf8');

    return decrypted;
}


export const formatNumber = (num) => {
    if (num < 1e3) return zeroFloor(num).toString();
    else if (num < 1e6) return zeroFloor(num / 1e3).toFixed(num % 1e3 > 1e2 ? 1 : 0) + 'K';
    else if (num < 1e9) return zeroFloor(num / 1e6).toFixed(num % 1e6 > 1e5 ? 1 : 0) + 'M';
    else if (num < 1e12) return zeroFloor(num / 1e9).toFixed(num % 1e9 > 1e8 ? 1 : 0) + 'B';
    else return zeroFloor(num / 1e12).toFixed(num % 1e12 > 1e11 ? 1 : 0) + 'T';
}


export const fib = (n) => {
    const phi = (1 + Math.sqrt(5)) / 2;  // Golden ratio
    const psi = (1 - Math.sqrt(5)) / 2;  // Conjugate of the golden ratio

    // Binet's formula: F(n) = (phi^n - psi^n) / sqrt(5)
    return Math.round((Math.pow(phi, n) - Math.pow(psi, n)) / Math.sqrt(5));
};


export const calcLevel = (expPoints) => {
    let level = 0;
    let currentLevelPoints = 0;

    while (currentLevelPoints <= expPoints) {
        level++;
        currentLevelPoints = fib(level) * 100;
    }

    return level - 1;
};


export function lowerCaseUnderscoredString(input) {
    // Step 1: Convert the string to lower case
    const lowerCaseString = input.toLowerCase();

    // Step 2: Replace spaces with underscores
    const underscoredString = lowerCaseString.replace(/\s+/g, '_');

    return underscoredString;
}


export const formatKeyText = (camelCase) => camelCase.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, (str) => str.toUpperCase());


export const zeroFloor = num => num > 0 ? num : 0


export const blobUrlToFile = async(blobUrl) => {
    try {
        // Use axios to fetch the blob data
        const blob = await fetch(blobUrl).then(res => res.blob())
        
        // Convert the Blob into a File object
        const file = new File([blob], 'cover image',{ type: blob.type });

        return file
    } catch (err) {
        devConsoleLog(`Failed to fetch blob data: ${err.message}`)

        return null
    }
}