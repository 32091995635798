"use client"

import { useEffect, useRef } from "react"

const useInterval = (callback, delay) => {
    const savedCallback = useRef(null)

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        const tick = () => {
            savedCallback.current()
        }

        if (Boolean(delay)) {
            let id = setInterval(tick, delay)

            return () => {
                clearInterval(id)
            }
        }
    }, [delay])
}

export default useInterval